<!-- Start Page Title Area -->
<div class="page-title-area bg-2">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Services Area -->
<section class="featured-services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-1.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-life-insurance-1"></i>
                        <h3><a routerLink="/business-insurance">Life Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-2.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-home-insurance"></i>
                        <h3><a routerLink="/business-insurance">Home Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-3.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-insurance"></i>
                        <h3><a routerLink="/business-insurance">Business Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-4.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-property-insurance"></i>
                        <h3><a routerLink="/business-insurance">Property Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-5.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-travel-insurance"></i>
                        <h3><a routerLink="/business-insurance">Travel Insurance</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-6.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
                        <i class="flaticon-car-insurance"></i>
                        <h3><a routerLink="/business-insurance">Auto Insurance</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->

<!-- Start Get Quote Area -->
<section class="get-quote-area get-quote-area-two services-page pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="get-quote-img jarallax" data-jarallax='{"speed": 0.3}'>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="tab get-quote-tab">
                    <div class="section-title">
                        <span>Get Quote</span>
                        <h2>Get Insurance Quote</h2>
                    </div>

                    <ul class="tabs">
                        <li>Life Insurance </li>
                        <li>Home Insurance</li>
                        <li>Auto Insurance</li>
                        <li>Travel Insurance</li>
                    </ul>
                    
                    <div class="tab_content">
                        <div class="tabs_item">
                            <form class="get-quote-form">
                                <div class="we-area-title">
                                    <h3>Life Insurance Quote</h3>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="all-skill-bar">
                                            <div class="skill-bar" data-percentage="25%">
                                                <h4 class="progress-title-holder">
                                                    <span class="progress-title">Leave of Protection:</span>
                                                    <span class="usd">$180.00</span>
                                                    <span class="progress-number-wrapper">
                                                        <span class="progress-number-mark">
                                                            <span class="percent"></span>
                                                            <span class="down-arrow"></span>
                                                        </span>
                                                    </span>
                                                </h4>
                                                <div class="progress-content-outter">
                                                    <div class="progress-content"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="col-lg-12 col-sm-12">
                                        <span class="from-title">Personal Information:</span>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <select>
                                                <option value="1">Employment Status</option>
                                                <option value="2">Services Man</option>
                                                <option value="3">Management</option>
                                                <option value="4">Receiver</option>
                                                <option value="5">Cline</option>
                                            </select>	
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <select>
                                                <option value="1">Your Age</option>
                                                <option value="2">29</option>
                                                <option value="3">18</option>
                                                <option value="4">22</option>
                                                <option value="5">30</option>
                                            </select>	
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-sm-12">
                                        <span class="from-title">Contact Details:</span>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="First-Name-1" placeholder="Name">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" id="Email-1" placeholder="Email">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <button type="submit" class="default-btn">Get a Quote</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        
                        <div class="tabs_item">
                            <form class="get-quote-form">
                                <div class="we-area-title">
                                    <h3>Home Insurance Quote</h3>
                                </div>
            
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="all-skill-bar">
                                            <div class="skill-bar" data-percentage="25%">
                                                <h4 class="progress-title-holder">
                                                    <span class="progress-title">Leave of Protection:</span>
                                                    <span class="usd">$180.00</span>
                                                    <span class="progress-number-wrapper">
                                                        <span class="progress-number-mark">
                                                            <span class="percent"></span>
                                                            <span class="down-arrow"></span>
                                                        </span>
                                                    </span>
                                                </h4>
                                                <div class="progress-content-outter">
                                                    <div class="progress-content"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="col-lg-12 col-sm-12">
                                        <span class="from-title">Personal Information:</span>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <select>
                                                <option value="1">Employment Status</option>
                                                <option value="2">Services Man</option>
                                                <option value="3">Management</option>
                                                <option value="4">Receiver</option>
                                                <option value="5">Cline</option>
                                            </select>	
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <select>
                                                <option value="1">Your Age</option>
                                                <option value="2">29</option>
                                                <option value="3">18</option>
                                                <option value="4">22</option>
                                                <option value="5">30</option>
                                            </select>	
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-sm-12">
                                        <span class="from-title">Contact Details:</span>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="First-Name-1" placeholder="Name">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" id="Email-1" placeholder="Email">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <button type="submit" class="default-btn">Get a Quote</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <form class="get-quote-form">
                                <div class="we-area-title">
                                    <h3>Auto Insurance Quote</h3>
                                </div>
            
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="all-skill-bar">
                                            <div class="skill-bar" data-percentage="25%">
                                                <h4 class="progress-title-holder">
                                                    <span class="progress-title">Leave of Protection:</span>
                                                    <span class="usd">$180.00</span>
                                                    <span class="progress-number-wrapper">
                                                        <span class="progress-number-mark">
                                                            <span class="percent"></span>
                                                            <span class="down-arrow"></span>
                                                        </span>
                                                    </span>
                                                </h4>
                                                <div class="progress-content-outter">
                                                    <div class="progress-content"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="col-lg-12 col-sm-12">
                                        <span class="from-title">Personal Information:</span>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <select>
                                                <option value="1">Employment Status</option>
                                                <option value="2">Services Man</option>
                                                <option value="3">Management</option>
                                                <option value="4">Receiver</option>
                                                <option value="5">Cline</option>
                                            </select>	
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <select>
                                                <option value="1">Your Age</option>
                                                <option value="2">29</option>
                                                <option value="3">18</option>
                                                <option value="4">22</option>
                                                <option value="5">30</option>
                                            </select>	
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-sm-12">
                                        <span class="from-title">Contact Details:</span>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="First-Name-1" placeholder="Name">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" id="Email-1" placeholder="Email">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <button type="submit" class="default-btn">Get a Quote</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="tabs_item">
                            <form class="get-quote-form">
                                <div class="we-area-title">
                                    <h3>Travel Insurance Quote</h3>
                                </div>
            
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="all-skill-bar">
                                            <div class="skill-bar" data-percentage="25%">
                                                <h4 class="progress-title-holder">
                                                    <span class="progress-title">Leave of Protection:</span>
                                                    <span class="usd">$180.00</span>
                                                    <span class="progress-number-wrapper">
                                                        <span class="progress-number-mark">
                                                            <span class="percent"></span>
                                                            <span class="down-arrow"></span>
                                                        </span>
                                                    </span>
                                                </h4>
                                                <div class="progress-content-outter">
                                                    <div class="progress-content"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="col-lg-12 col-sm-12">
                                        <span class="from-title">Personal Information:</span>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <select>
                                                <option value="1">Employment Status</option>
                                                <option value="2">Services Man</option>
                                                <option value="3">Management</option>
                                                <option value="4">Receiver</option>
                                                <option value="5">Cline</option>
                                            </select>	
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <select>
                                                <option value="1">Your Age</option>
                                                <option value="2">29</option>
                                                <option value="3">18</option>
                                                <option value="4">22</option>
                                                <option value="5">30</option>
                                            </select>	
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-sm-12">
                                        <span class="from-title">Contact Details:</span>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="First-Name-1" placeholder="Name">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" id="Email-1" placeholder="Email">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="Number-1" placeholder="Phone Number">
                                        </div>
                                    </div>
            
                                    <div class="col-lg-6 col-sm-6">
                                        <button type="submit" class="default-btn">Get a Quote</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Get Quote Area -->