<!-- Start Page Title Area -->
<div class="page-title-area bg-4">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Agents</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Our Agents</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Team Area -->
<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/img/team/team-1.jpg" alt="Image">
                        <ul class="team-link">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Thomas Brand</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
        
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/img/team/team-2.jpg" alt="Image">
                        <ul class="team-link">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Janie Abram</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>
        
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/img/team/team-3.jpg" alt="Image">
                        <ul class="team-link">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Jeffrey Garcia</h3>
                        <span>Branch Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/img/team/team-4.jpg" alt="Image">
                        <ul class="team-link">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Mildred Martin</h3>
                        <span>Director of Marketing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/img/team/team-5.jpg" alt="Image">
                        <ul class="team-link">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Ronald Phaneuf</h3>
                        <span>Marketing Support</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/img/team/team-6.jpg" alt="Image">
                        <ul class="team-link">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Maria Sledge</h3>
                        <span>Agent</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->