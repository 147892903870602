import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homethree-future',
  templateUrl: './homethree-future.component.html',
  styleUrls: ['./homethree-future.component.scss']
})
export class HomethreeFutureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
