<!-- Start 404 Error -->
<div class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
            <h1><span class="a">4</span> <span class="red">0</span> <span class="b">4</span> </h1>
            <h3>Oops! Page Not Found</h3>
            <p>The page you were looking for could not be found.</p>
            <a routerLink="/" class="default-btn two">Return To Home Page</a>
        </div>
    </div>
</div>
<!-- End 404 Error -->