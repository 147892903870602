<!-- Start Page Title Area -->
<div class="page-title-area bg-5">
    <div class="container">
        <div class="page-title-content">
            <h2>Testimonials</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Testimonials</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<app-homethree-feedback></app-homethree-feedback>

<app-homeone-feedback></app-homeone-feedback>

<app-hometwo-feedback></app-hometwo-feedback>