import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeone-future',
  templateUrl: './homeone-future.component.html',
  styleUrls: ['./homeone-future.component.scss']
})
export class HomeoneFutureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
